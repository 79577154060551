import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  }

  render() {
    const pageNames = [
      "Home",
      "About",
      // "Services",
      // "ShippingRates",
      "Contact"
    ];

    return (
      <div>
        <div className="header-area__desktop header-area__desktop--default">
          <div className="header-info-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="header-info-wrapper align-items-center">
                    <div className="logo">
                      <Link to={`${process.env.PUBLIC_URL}/home`}>
                        <img src="assets/img/logo/logo.png" className="img-fluid" alt="Logo" style={{ width: '100vh', objectFit: "cover" }} />
                      </Link>
                    </div>
                    <div className="header-contact-info">
                      <div className="header-info-single-item">
                        <div className="header-info-single-item__icon">
                          <i className="zmdi zmdi-smartphone-android" />
                        </div>
                        <div className="header-info-single-item__content">
                          <h6 className="header-info-single-item__title">Chat on WhatsApp</h6>
                          <p className="header-info-single-item__subtitle"><a href="https://wa.me/905316229804">+(90) 531 622 98 04</a></p>
                        </div>
                      </div>
                      <div className="header-info-single-item">
                        <div className="header-info-single-item__icon">
                          <i className="zmdi zmdi-home" />
                        </div>
                        <div className="header-info-single-item__content">
                          <h6 className="header-info-single-item__title">e-Mail Address</h6>
                          <p className="header-info-single-item__subtitle"><a href="mailto:info@demirekol.com">info@demirekol.com</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.activeMobileMenu}>
                      <i />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-navigation-area default-bg" style={{ backgroundColor: '#AB2222' }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="header-navigation header-navigation--header-default position-relative">
                    <div className="header-navigation__nav position-static">
                      <nav>
                        <ul>
                          {pageNames.map((pageName, index) => (
                            <li key={index}>
                              <Link to={`${process.env.PUBLIC_URL}/${pageName.toLowerCase()}`}>{pageName}</Link>
                            </li>
                          ))}
                          <li className="has-children has-children--multilevel-submenu">
                            <Link to={`${process.env.PUBLIC_URL}/Food`}>Products</Link>
                            <ul className="submenu">
                              <li><Link to={`${process.env.PUBLIC_URL}/Food`}>Food</Link></li>
                              <li><Link to={`${process.env.PUBLIC_URL}/Drinks`}>Drinks</Link></li>
                              <li><Link to={`${process.env.PUBLIC_URL}/ClothesFabricsandTextiles`}>Clothes, Fabrics & Textiles</Link></li>
                              <li><Link to={`${process.env.PUBLIC_URL}/ShoesandLeatherProducts`}>Shoes & Leather Products</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
