import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class MobileMenu extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){
        return(
            <div>
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </a>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                                <ul>
                                    <li><Link to={`${process.env.PUBLIC_URL}/Home`}>Home</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/About`}>About</Link></li>
                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/Services`}>Services</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/ShippingRates`}>Shipping Rates</Link></li> */}
                                    <li><Link to={`${process.env.PUBLIC_URL}/Contact`}>Contact</Link></li>
                                    <li className="menu-item-has-children">
                                      <Link to={`${process.env.PUBLIC_URL}/Food`}>Products</Link>
                                        <ul className="sub-menu">
                                            <li><Link to={`${process.env.PUBLIC_URL}/Food`}>Food</Link></li>  
                                            <li><Link to={`${process.env.PUBLIC_URL}/Drinks`}>Drinks</Link></li>
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/MedicalMaterialsandDevices`}>Medical Materials & Devices</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/BabyProducts`}>Baby Products</Link></li> */}
                                            <li><Link to={`${process.env.PUBLIC_URL}/ClothesFabricsandTextiles`}>Clothes, Fabrics and Textiles</Link></li>
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/FinishingAccessories`}>Finishing Accessories</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/FurnitureandFixtures`}>Furniture & Fixtures</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/SchoolSuppliesandStationery`}>School Supplies & Stationery</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/PackingandPackaging`}>Packing & Packaging</Link></li> */}
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/IndustrialMachineryandAccessories`}>Industrial Machinery & Accessories (Electric Items)</Link></li> */}
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/AgriculturalandVeterinaryMaterials`}>Agricultural & Veterinary Materials</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/ChemicalProductsTrade`}>Chemical Products Trade</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/Detergent`}>Detergent</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/OtherTurkishProducts`}>Other Turkish Products</Link></li> */}
                                            <li><Link to={`${process.env.PUBLIC_URL}/ShoesandLeatherProducts`}>Shoes & Leather Products</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                          
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MobileMenu;