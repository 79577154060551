import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Contact extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundColor:'grey'
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>CONTACT US</h1>
                  <ul className="page-breadcrumb">
                    <li style={{color:'#fff', fontSize:20}}>DMR Demir Ekol Food Textiles Import Export Industry Trade and Limited Company</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-wrapper section-space--inner--120">
          <div className="conact-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="contact-information">
                  <h2 style={{textAlign:'center'}}>Contact Information</h2>
                    <hr/>
                    <ul>
                      <li>
                        <span className="icon">
                        <i className="fas fa-phone-alt" />
                        </span>
                        <span className="text">
                          <a href="https://wa.me/905316229804">+(90) 531 622 98 04</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                        <i className="far fa-envelope" />
                        </span>
                        <span className="text">
                          <a href="mailto:info@demirekol.com">info@demirekol.com</a>
                        </span>
                      </li>
                      {/* <li>
                        <span className="icon">
                        <i className="fab fa-facebook-square" />
                        </span>
                        <span className="text">
                          <a href="https://www.facebook.com/">www.facebook.com</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                        <i className="fab fa-instagram" />
                        </span>
                        <span className="text">
                          <a href="https://www.instagram.com//">www.instagram.com</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                        <i className="fab fa-twitter" />
                        </span>
                        <span className="text">
                          <a href="https://www.x.com/">x.com</a>
                        </span>
                      </li> */}
                      <li>
                        <span className="icon">
                        <i className="fas fa-map-marker-alt" />
                        </span>
                        <span className="text">
                          <span>
                            Mahmutbey Mah. Taşocğı Yolu Cad. Balance Güneşli Sk. No:19/1A Bağcılar-İSTANBUL
                          </span>
                        </span>
                      </li>
                    </ul>
                    <hr/>
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="contact-form">
                    <h3>We'll Be Happy to Provide the Best Service</h3>
                    <form id="contact-form">
                      <div className="row row-10">
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_name"
                            type="text"
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_companyname"
                            type="text"
                            placeholder="Company Name"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_email"
                            type="email"
                            placeholder="e-Mail"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_telephone"
                            type="telephone"
                            placeholder="Telephone Number"
                          />
                        </div>
                        <div className="col-12">
                          <textarea
                            name="con_message"
                            placeholder="How Can The Sales Team Help You Today?"
                            defaultValue={""}
                          />
                        </div>
                        <div className="col-12">
                          <button>Request Consultation</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <MobileMenu />
      </div>
    );
  }
}
export default Contact;