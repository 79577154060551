import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class HomeServices extends Component {
  render() {
    let data = [
      {
        iconClass: "flaticon-002-welding",
        serviceTitle: "Products",
        serviceSubtitle:
          "Searching for products and contracting with the best sources"
      },
      {
        iconClass: "flaticon-004-walkie-talkie",
        serviceTitle: "Price",
        serviceSubtitle:
          "Negotiation service to get the best prices and discounts"
      },
      {
        iconClass: "flaticon-015-cart",
        serviceTitle: "Supervision",
        serviceSubtitle:
          "Control over products and the receipt of goods and their conformity to specifications"
      },
      {
        iconClass: "flaticon-010-tank-1",
        serviceTitle: "Customs Clearance",
        serviceSubtitle:
          "Customs clearance services in Turkey through Necibe Company’s specialized team"
      },
      {
        iconClass: "flaticon-004-walkie-talkie",
        serviceTitle: "Shipping",
        serviceSubtitle:
          "Providing the best offers (shipping and delivery, loading and unloading, shipping and payment)"
      },
   
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-md-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                
              </div>
              <div className="icon">
                <i className={val.iconClass} />
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  {val.serviceTitle}
                </a>
              </h3>
              <p className="subtitle">{val.serviceSubtitle}</p>
              
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
   
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundColor:'gray'
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Our Services</h1>
                  <ul className="page-breadcrumb">
                    <li>Necibe Company pays great attention to securing the necessary services that help its clients to boost commercial exchanges and secure what is necessary to complete their deals in a safe and reliable manner</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  service page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>


      </div>
    );
  }
}

export default HomeServices;
