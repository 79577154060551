import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import ServiceGridSlider from '../components/ServiceGridSlider';
import VideoCta from '../components/VideoCta';
import HomeServices from '../Services/HomeServices';
import BlogGrid from '../components/BlogGrid';
import Footer from '../components/Footer';


class Home extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>


               {/* Video CTA */}
               <VideoCta/>
                
                
                 {/* Service grid slider */}
                 <ServiceGridSlider/>
              
             
                
                {/* Blog grid */}
                <BlogGrid background="grey-bg" />

              
                {/* <HomeServices/> */}

                {/* Footer */}
                <Footer/>

            </div>
        )
    }
}


export default Home;