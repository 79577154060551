import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class ShippingRates extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Shipping Rates</h1>
                  <ul className="page-breadcrumb">
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Contact section start*/}
          <div className="conact-section">
            <div className="container">
         
              <div className="row">
                {/* <div className="col-lg-4 col-12">
                  <div className="contact-information">
                    <h3>Contact Information</h3>
                    <ul>
                      
                      <li>
                        <span className="icon">
                          <i className="ion-ios-telephone-outline" />
                        </span>
                        <span className="text">
                          <a href="tel:1234567890">(+90) 541 144 3404</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="https://www.necibe333.com">www.necibe333.com</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="https://www.facebook.com/necibe333">www.facebook.com/necibe333</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="https://www.instagram.com/necibe333/">www.instagram.com/necibe333/</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="https://www.x.com/necibe333">x.com/necibe333</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-android-map" />
                        </span>
                        <span className="text">
                          <span>
                          Mahmutbey Mah. Taşocağı Yolu Cad. Balance Güneşli Blok No:19/1A Bağcılar/İstanbul
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-lg-12 col-12">
                  <div className="contact-form">
                    <h3>Contact Information</h3>
                    <form id="contact-form">
                      <div className="row row-20">
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_name"
                            type="text"
                            placeholder="Start Location*"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_companyname"
                            type="text"
                            placeholder="Company Name*"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_email"
                            type="email"
                            placeholder="e-Mail"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_telephone"
                            type="telephone"
                            placeholder="Phone Number*"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_telephone"
                            type="telephone"
                            placeholder="e-Mail Address*"
                          />
                        </div>

                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            name="con_telephone"
                            type="telephone"
                            placeholder="More Details*"
                          />
                        </div>
                      
                     
                        
                        <div className="col-12">
                          <button>Send</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default ShippingRates;
