import React, {Component} from 'react';

class BlogGrid extends Component{
    render(){
        let data = [
            {img:'rice.jpg',  title: 'FOOD', postExcerpt: 'Legume and grain, canned food and cans, dried fruits and nuts, oil, animal products, meat and fish'},
            {img:'juice.jpg', title: 'DRINKS', postExcerpt: 'DMR Demir Ekol Company offers this section a group of Turkish products including natural water, mineral water, juice, soft drinks, and energy drinks'},
            {img:'textile.jpg',  title: 'CLOTHES, FABRICS & TEXTILES', postExcerpt: 'Hospital Furniture, prosthetics, surgical instruments, dental supplies, and medical materials and supplies'},
            {img:'men-boots.jpg',  title: 'SHOES & LEATHER PRODUCTS', postExcerpt: 'Men`s shoes, boots and slippers, women`s shoes, boots and slippers, children`s shoes, boots and slippers, baby shoes...'},
            // {img:'products-5.jpg',  title: 'Clothes, Fabrics and Textiles', postExcerpt: 'Turkish clothing, fabrics and textiles trade. Wholesale at the best prices with full door to door services.'},
            // {img:'products-6.jpg',  title: 'Finishing Accessories', postExcerpt: 'The trade of construction materials, interior cladding, kitchen, and bathroom accessories, in addition to roads and garden equipment'},
            // {img:'products-7.jpg',  title: 'Furniture & Fixtures', postExcerpt: 'The trade of household furniture, office furniture, home materials and equipment, hotel, hospital, and sports club furniture'},
            // {img:'products-8.jpg',  title: 'School Suppiles & Stationery', postExcerpt: 'Schools and kindergartens Equipment, stationery, and office supplies'}


      
        ];

        let DataList = data.map((val, i) => {
            return (
                <div className="col-lg-3" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                            <img src={`assets/img/products/${val.img}`} className="img-fluid" alt="" style={{ width: '100%', height: '20rem' }} />
                        </div>
                        <div className="blog-post-slider__content">
                            <p className="post-date"> {val.date}</p>
                            <h3 className="post-title">
                                <span>{val.title}</span>
                            </h3>
                            <p className="post-excerpt">{val.postExcerpt}</p>
                            <span className="see-more-link"></span>
                        </div>
                    </div>
                </div>
            );
        });
        

        return(
            <div>
                {/*====================  blog grid area ====================*/}
                <div className={`blog-grid-area section-space--inner--120 ${this.props.background}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="">
                                    <h2 className='section-h2' style={{color:'#AB2222'}}>PRODUCT CATEGORIES</h2>
                                <h5 className="section-title1 section-space--bottom--50" style={{color:'#000'}}> As DMR Demir Ekol works on covering the needs of its customers from the local or
 global market of the main commodities and various production sectors, not
 only that but also guaranteeing all of that at competitive prices. </h5>
                                </div>
                            </div>
                            <div className="col-lg-12 ">
                                <div className="blog-grid-wrapper">
                                <div className="row ">
                                    {DataList}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of blog grid area  ====================*/}

            </div>
        )
    }
}


export default BlogGrid;