import React, {  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./Services/Services";
import ShippingRates from "./pages/ShippingRates";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import { Food, Drinks, MedicalMaterialsandDevices, BabyProducts, ClothesFabricsandTextiles, FinishingAccessories, FurnitureandFixtures, SchoolSuppliesandStationery, PackingandPackaging, IndustrialMachineryandAccessories, AgriculturalandVeterinaryMaterials, ChemicalProductsTrade, Detergent, OtherTurkishProducts, ShoesandLeatherProducts } from "./Products";

const App=()=>{


const subpages=[
  {name:"Food",component:<Food/>},
  {name:"Drinks",component:<Drinks/>},
  {name:"MedicalMaterialsandDevices",component:<MedicalMaterialsandDevices/>},
  {name:"BabyProducts",component:<BabyProducts/>},
  {name:"ClothesFabricsandTextiles",component:<ClothesFabricsandTextiles/>},
  {name:"FinishingAccessories",component:<FinishingAccessories/>},
  {name:"FurnitureandFixtures",component:<FurnitureandFixtures/>},
  {name:"SchoolSuppliesandStationery",component:<SchoolSuppliesandStationery/>},
  {name:"PackingandPackaging",component:<PackingandPackaging/>},
  {name:"IndustrialMachineryandAccessories",component:<IndustrialMachineryandAccessories/>},
  {name:"AgriculturalandVeterinaryMaterials",component:<AgriculturalandVeterinaryMaterials/>},
  {name:"ChemicalProductsTrade",component:<ChemicalProductsTrade/>},
  {name:"Detergent",component:<Detergent/>},
  {name:"OtherTurkishProducts",component:<OtherTurkishProducts/>},
  {name:"ShoesandLeatherProducts",component:<ShoesandLeatherProducts/>}


]

  return(
    <Router >
      <Suspense fallback={<div/>}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Home" element={<Home/>}/>
          <Route path="/About" element={<About/>}/>
          <Route path="/Services" element={<Services/>}/>
          <Route path="/ShippingRates" element={<ShippingRates/>}/>
          <Route path="/Contact" element={<Contact/>}/>
          <Route path="*" element={<PageNotFound/>}/>
          {subpages&&subpages.map((d)=>(
            <Route key={d.name} path={"/"+d.name} element={d.component}/>
          ))}
          
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;