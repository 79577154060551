	
import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
export class Food extends Component {
  render() {
    let data = [
      {
        // pageLink: "*",
        img: "lentil.jpg",
        projectTitle: "LENTIL",
        projectSubtitle:
          "Lentil"
      },
      {
        // pageLink: "*",
        img: "green-lentil.jpg",
        projectTitle: "GREEN LENTIL",
        projectSubtitle:
          "Green Lentil"
      },
      {
        // pageLink: "*",
        img: "rice.jpg",
        projectTitle: "RICE",
        projectSubtitle:
          "Rice"
      },
      {
        // pageLink: "*",
        img: "bulgur.jpg",
        projectTitle: "BULGUR",
        projectSubtitle:
          "Bulgur"
      },
      {
        // pageLink: "*",
        img: "chickpea.jpg",
        projectTitle: "CHICKPEA",
        projectSubtitle:
          "Chickpea"
      },
      {
        // pageLink: "*",
        img: "pea.jpg",
        projectTitle: "PEA",
        projectSubtitle:
          "Pea"
      },
      {
        // pageLink: "*",
        img: "bean.jpg",
        projectTitle: "BEAN",
        projectSubtitle:
          "Bean"
      },
      {
        // pageLink: "*",
        img: "corn.jpg",
        projectTitle: "CORN",
        projectSubtitle:
          "Corn"
      },
      {
        // pageLink: "*",
        img: "ketchup.jpg",
        projectTitle: "KETCHUP",
        projectSubtitle:
          "Ketchup"
      },
      {
        // pageLink: "*",
        img: "mayonnaise.jpg",
        projectTitle: "MAYONNAISE",
        projectSubtitle:
          "Mayonnaise"
      },
      {
        // pageLink: "*",
        img: "milk.jpg",
        projectTitle: "MILK",
        projectSubtitle:
          "Milk"
      },
      {
        // pageLink: "*",
        img: "cheese.jpg",
        projectTitle: "CHEESE",
        projectSubtitle:
          "Cheese"
      },
      {
        // pageLink: "*",
        img: "vegetables.jpg",
        projectTitle: "FROZEN VEGETABLES",
        projectSubtitle:
          "Frozen Vegetables"
      },
      {
        // pageLink: "*",
        img: "fish.jpg",
        projectTitle: "FROZEN FISH",
        projectSubtitle:
          "Frozen Fish"
      },
      {
        // pageLink: "*",
        img: "chicken.jpg",
        projectTitle: "FROZEN CHICKEN",
        projectSubtitle:
          "Frozen Chicken"
      },
      {
        // pageLink: "*",
        img: "hazelnut.jpg",
        projectTitle: "HAZELNUT",
        projectSubtitle:
          "Hazelnut"
      },
      {
        // pageLink: "*",
        img: "pistachio.jpg",
        projectTitle: "PISTACHIO",
        projectSubtitle:
          "Pistachio"
      },
      {
        // pageLink: "*",
        img: "walnuts.jpg",
        projectTitle: "WALNUTS",
        projectSubtitle:
          "Walnuts"
      },
      {
        // pageLink: "*",
        img: "chocolate-cream.jpg",
        projectTitle: "CHOCOLATE CREAM",
        projectSubtitle:
          "Chocolate Cream"
      },
      {
        // pageLink: "*",
        img: "olive-oil.jpg",
        projectTitle: "OLIVE OIL",
        projectSubtitle:
          "Olive Oil"
      },
      {
        // pageLink: "*",
        img: "sunflower-oil.jpg",
        projectTitle: "SUNFLOWER OIL",
        projectSubtitle:
          "Sunflower Oil"
      },
      {
        // pageLink: "*",
        img: "dried-fig.jpg",
        projectTitle: "DRIED FIG",
        projectSubtitle:
          "Dried Fig"
      },
      {
        // pageLink: "*",
        img: "dried-apricot.jpg",
        projectTitle: "DRIED APRICOT",
        projectSubtitle:
          "Dried Apricot"
      },
      {
        // pageLink: "*",
        img: "raisins.jpg",
        projectTitle: "RAISINS",
        projectSubtitle:
          "Raisins"
      },
      {
        // pageLink: "*",
        img: "jam.jpg",
        projectTitle: "JAM",
        projectSubtitle:
          "Jam"
      },
      {
        // pageLink: "*",
        img: "canned-food.jpg",
        projectTitle: "CANNED FOOD",
        projectSubtitle:
          "Canned Food"
      }
    ];

let Datalist = data.map((val, i) => {
  return (
    <div
      className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
      key={i}
    >
      <div className="service-grid-item service-grid-item--style2">
        <div className="service-grid-item__image">
          <div className="service-grid-item__image-wrapper">
            {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}> */}
              <img
                src={`assets/img/products/${val.img}`}
                className="img-fluid"
                alt=""
                style={{width:'100%', height:'20rem', objectFit:'cover'}}
              />
            {/* </a> */}
          </div>
        </div>
        <div className="service-grid-item__content">
          <h3 className="title">
            {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}> */}
              {val.projectTitle}
            {/* </a> */}
          </h3>
          <p className="subtitle">{val.projectSubtitle}</p>
          
        </div>
      </div>
    </div>
  );
});

return (
  <div>
    {/* Navigation bar */}
    <NavBar />

    {/* breadcrumb */}
    {/*====================  breadcrumb area ====================*/}
    <div
      className="breadcrumb-area breadcrumb-bg"
      style={{backgroundColor:'gray'}}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="page-banner text-center">
              <h1>PRODUCTS</h1>
              <ul className="page-breadcrumb">
                <li>
                  <a href='/home'>Home</a> - <a href="/Food">Products</a> - Food
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*====================  End of breadcrumb area  ====================*/}

    {/*====================  project page content ====================*/}
    <div className="page-wrapper section-space--inner--120">
      {/*Projects section start*/}
      <div className="project-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-item-wrapper">
                <div className="row">{Datalist}</div>
              </div>
            </div>
          </div>
          {/* <div className="row section-space--top--60">
            <div className="col">
              <ul className="page-pagination">
                <li>
                  <a href="/">
                    <i className="fa fa-angle-left" /> Prev
                  </a>
                </li>
                <li className="active">
                  <a href="/">01</a>
                </li>
                <li>
                  <a href="/">02</a>
                </li>
                <li>
                  <a href="/">03</a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-right" /> Next
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/*Projects section end*/}
    </div>

    {/*====================  End of project page content  ====================*/}

    {/* Brand logo */}
    <BrandLogoSlider background="grey-bg" />

    {/* Footer */}
    <Footer />

    {/* Mobile Menu */}
    <MobileMenu />
  </div>
);
  }
}

