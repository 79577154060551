import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        <NavBar />
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundColor:"gray"
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1 style={{color:'#fff'}}>ABOUT US</h1>
                  <ul className="page-breadcrumb">
                    <li style={{color:'#fff', fontSize:20}}><strong>DMR Demir Ekol Food Textiles Import Export Industry Trade and Limited Company</strong></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        
            <div className="container">
                
                
                    
                    <br/><br/>
                    <h2 style={{textAlign:'center', color:'#AB2222'}}><span>Who We Are?<br/></span></h2>
                    <p style={{textAlign:'center'}}>
                      <span>Our company is a dynamic trading entity focusing on a broad spectrum of products,<br/> ranging from high-quality foods and non-alcoholic beverages to garments, fabrics,<br/> textiles, footwear, and leather goods. With our values and expert team, we are dedicated<br/> to providing customers with a unique and satisfaction-oriented shopping experience.</span>
                    </p>
                    
                    <br/><br/>
                    <h2 style={{textAlign:'center', color:'#AB2222'}}><span>Our Mission<br/></span></h2>
                    <p style={{textAlign:'center'}}>
                      <span>Our mission is to enhance the quality of life for customers by offering quality, diverse,<br/> and sustainable products. Prioritizing customer satisfaction in every aspect,<br/> we embrace a trustworthy and ethical approach to trade. Conscious of our<br/> social and environmental responsibilities, we aim to make positive contributions<br/> to society and the environment.</span>
                    </p>
                    
                    <br/><br/>
                    <h2 style={{textAlign:'center', color:'#AB2222'}}><span>Our Vision<br/></span></h2>
                    <p style={{textAlign:'center'}}>
                      <span>Our vision is to attain a leadership position in the industry by providing customers<br/> with value-driven, innovative, and sustainable solutions. Aspiring to be a globally<br/> recognized brand, we aim to continuously improve and adapt. Through collaboration<br/> and creativity within our team, we strive to maintain our pioneering role in the industry<br/> by delivering an inspiring shopping experience to our customers.</span>
                    </p>
                    <br/>
                    
                  </div>
                
              

        <Footer />

        <MobileMenu />
      </div>
    );
  }
}

export default About;
