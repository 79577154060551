import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'

class VideoCta extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }
    render(){
        return(
            <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundColor:"gray"
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1 style={{color:'fff'}}>DMR Demir Ekol</h1>
                  <ul className="page-breadcrumb">
                    <li style={{color:'#fff', fontSize:20}}>DMR Demir Ekol Company specializes in exporting goods and products of all kinds from Turkey to all parts of the world whether the goods are of its production, manufactured for it, or other ready goods, the company is characterized by its high professionalism as well as credibility in its business.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
    }
}


export default VideoCta;