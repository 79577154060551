import React, {Component} from 'react';
import SwiperSlider, { SwiperSlide } from "./swiper"

class ServiceGridSlider extends Component{
    render(){
        const params = {
            slidesPerView : 3,
            loop: true,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            // Responsive breakpoints
            breakpoints: {
                300:{
                    slidesPerView : 1
                },
                768:{
                    slidesPerView : 2

                },
                992:{
                    slidesPerView : 3
                }
            }
        }
        let data = [
            { iconName: 'flaticon-002-welding', serviceTitle: 'Receiving Order', },
            { iconName: 'flaticon-004-walkie-talkie', serviceTitle: 'Finding The Best Product'},
            { iconName: 'flaticon-015-cart', serviceTitle: 'Manufacturing The Product'},
            { iconName: 'flaticon-010-tank-1', serviceTitle: 'Packing and Packaging'},
            { iconName: 'flaticon-010-tank-1', serviceTitle: 'Customs Clearance'},
            { iconName: 'flaticon-010-tank-1', serviceTitle: 'Shipping and Delivery'},

        ];

        let DataList = data.map((val, i)=>{
            return(
                <SwiperSlide key={i}>
                    <div className="swiper-slide">
                        <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}>
                                <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="" />
                            </a>
                            </div>
                            <div className="icon">
                            <i className={val.iconName} />
                            </div>
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}>{val.serviceTitle}</a>
                            </h3>
                            <p className="subtitle">{val.serviceExcerpt}</p>
                        </div>
                        </div>
                    </div>
                </SwiperSlide>
            )
        });
        return(
            <div>
                {/*====================  service grid slider area ====================*/}
                <div className="service-grid-slider-area section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title" style={{color:'#AB2222'}}>How do I import goods from Turkey?</h2><br/><br/>
                        <p>
     DMR Demir Ekol Company makes it easy for companies and businessmen to find what they want in the Turkish market The
     customer’s request passes through several stages, they are:
 </p><br />
                        </div>
                    </div>
                    <div className="col-lg-12 ">
                        <div className="service-slider">
                            <SwiperSlider options={params}>
                                {DataList}
                            </SwiperSlider>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
        )
    }
}


export default ServiceGridSlider;