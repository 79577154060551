import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  render() {
    return (
      <div>
        <div className="footer-area" style={{backgroundColor:'#000'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--100">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-10">
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo">
                          <a href={`${process.env.PUBLIC_URL}/Home`}>
                            <br/><img
                              src="assets/img/logo/logo.png"
                              className="img-fluid"
                              alt=""
                              style={{width:'100%', height:'10rem', objectFit:"cover"}}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 offset-xl-1 col-lg-3 col-md-6">
                      <div className="footer-widget">
                        <h4 className="footer-widget__title"><u>CONTACT INFO</u></h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href="mailto:info@demirekol.com">• e-Mail: info@demirekol.com</a>
                          </li>
                          <br/>
                          <li>
                            <a href="https://wa.me/905316229804">
                            • Phone Number: +(90) 531 622 98 04
                            </a>
                          </li>
                          <br/>
                          <li>
                            <a href="#">
                          • Company Address: Mahmutbey Mah. Taşocağı<br/>Yolu Cad. Balance Güneşli Sk. No: 19/1A Bağcılar-İSTANBUL</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      <div className="footer-widget">
                        <h4 className="footer-widget__title"><u>HELPFUL LİNKS</u></h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href="/Home">• Home</a>
                          </li>
                          <li>
                            <a href="/About">
                            • About
                            </a>
                          </li>
                          <li>
                            <a href="/Contact">• Contact</a>
                          </li>
                          <li>
                            <a href="/Food">• Products</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              <p className="copyright-text">
                All Rights Reserved for DMR Demir Ekol Textiles Import Export Industry Trade & Limited Company &copy; {new Date().getFullYear()} <a href="*">Privacy Policy</a>
              </p>
            </div>
          </div>
        </div>
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
      </div>
    );
  }
}
export default Footer;