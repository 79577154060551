import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
class Services extends Component {
  render() {
    let data = [
      {
        iconClass: "flaticon-002-welding",
        serviceTitle: "Products",
        serviceSubtitle:
          "Searching for products and contracting with the best sources"
      },
      {
        iconClass: "flaticon-004-walkie-talkie",
        serviceTitle: "Price",
        serviceSubtitle:
          "Negotiation service to get the best prices and discounts"
      },
      {
        iconClass: "flaticon-015-cart",
        serviceTitle: "Supervision",
        serviceSubtitle:
          "Control over products and the receipt of goods and their conformity to specifications"
      },
      {
        iconClass: "flaticon-010-tank-1",
        serviceTitle: "Customs Clearance",
        serviceSubtitle:
          "Customs clearance services in Turkey through Necibe Company’s specialized team"
      },
      {
        iconClass: "flaticon-004-walkie-talkie",
        serviceTitle: "Shipping",
        serviceSubtitle:
          "Providing the best offers (shipping and delivery, loading and unloading, shipping and payment)"
      },
   
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-md-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                
              </div>
              <div className="icon">
                <i className={val.iconClass} />
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  {val.serviceTitle}
                </a>
              </h3>
              <p className="subtitle">{val.serviceSubtitle}</p>
              
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
      <NavBar />
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundColor:'#AB2222'
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1> Services</h1>
                  <ul className="page-breadcrumb">
                    <li>We are working to provide the necessary support in the field of exporting Turkish goods, starting with specifying the best suppliers and manufacturers...</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  service page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
         
          <div className="service-section">
            
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                <div class="about-wrapper row">
    <div class="col-lg-6 col-12 order-3 order-lg-1">
        <div class="about-content about-content-1">
            <h1>
                <span>Who Are Necibe</span>
            </h1>
            <p>
                <span>"Necibe"</span> International Trade Company constitutes a vital investment addition to
                <span>"Imtilak Group"</span> known for its multiple activities and its distinction in many fields, since
                its launch in Turkey back in 2012, with experience extending to before the year 2000.
            </p>
            <p>
                <span>Necibe</span> Company specializes in exporting goods and products of all kinds from Turkey to all
                parts of the world whether the goods are of its production, manufactured for it, or other ready goods, the
                company is characterized by its high professionalism as well as credibility in its business.
            </p>
        </div>
    </div>

    <div class="col-lg-6 col-12 order-3 order-lg-1">
        <div class="video-container">
            <video controls width="100%" height="auto">
                <source src="assets\img\video\video1.mp4" type="video/mp4" />
            </video>
        </div>
    </div>
</div>

                  <div className="service-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        <Footer />
      </div>
    );
  }
}

export default Services;
