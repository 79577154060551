
import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
export class ClothesFabricsandTextiles extends Component {
  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "womens-clothing.jpg",
        projectTitle: "WOMEN`s CLOTHING",
        projectSubtitle:
          "Women`s Clothing"
      },
      {
        // pageLink: "project-details",
        img: "mens-clothing.jpg",
        projectTitle: "MEN`s CLOTHING",
        projectSubtitle:
          "Men`s Clothing"
      },
      {
        // pageLink: "project-details",
        img: "childrens-clothing.jpg",
        projectTitle: "CHILDREN`s CLOTHING",
        projectSubtitle:
          "Children`s Clothing"
      },
      {
        // pageLink: "project-details",
        img: "textile.jpg",
        projectTitle: "TEXTILE",
        projectSubtitle:
          "Textile"
      },
      {
        // pageLink: "project-details",
        img: "upholstery-textile.jpg",
        projectTitle: "UPHOLSTERY TEXTILE",
        projectSubtitle:
          "Upholstery Textile"
      },
      {
        // pageLink: "project-details",
        img: "curtain-fabric.jpg",
        projectTitle: "CURTAIN FABRIC",
        projectSubtitle:
          "Curtain Fabric"
      },
      {
        // pageLink: "project-details",
        img: "threads.jpg",
        projectTitle: "THREAD",
        projectSubtitle:
          "Thread"
      }
    ];

let Datalist = data.map((val, i) => {
  return (
    <div
      className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
      key={i}
    >
      <div className="service-grid-item service-grid-item--style2">
        <div className="service-grid-item__image">
          <div className="service-grid-item__image-wrapper">
            {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}> */}
              <img
                src={`assets/img/products/${val.img}`}
                className="img-fluid"
                alt=""
                style={{width:'100%', height:'20rem', objectFit:'cover'}}
              />
            {/* </a> */}
          </div>
        </div>
        <div className="service-grid-item__content">
          <h3 className="title">
            {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}> */}
              {val.projectTitle}
            {/* </a> */}
          </h3>
          <p className="subtitle">{val.projectSubtitle}</p>
          
        </div>
      </div>
    </div>
  );
});

return (
  <div>
    {/* Navigation bar */}
    <NavBar />

    {/* breadcrumb */}
    {/*====================  breadcrumb area ====================*/}
    <div
      className="breadcrumb-area breadcrumb-bg"
      style={{
        // backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
        backgroundColor:'gray'
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="page-banner text-center">
              <h1>PRODUCTS</h1>
              <ul className="page-breadcrumb">
                <li>
                <a href='/home'>Home</a> - <a href="/Food">Products</a> - Clothes, Fabrics & Textiles
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*====================  End of breadcrumb area  ====================*/}

    {/*====================  project page content ====================*/}
    <div className="page-wrapper section-space--inner--120">
      {/*Projects section start*/}
      <div className="project-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-item-wrapper">
                <div className="row">{Datalist}</div>
              </div>
            </div>
          </div>
          {/* <div className="row section-space--top--60">
            <div className="col">
              <ul className="page-pagination">
                <li>
                  <a href="/">
                    <i className="fa fa-angle-left" /> Prev
                  </a>
                </li>
                <li className="active">
                  <a href="/">01</a>
                </li>
                <li>
                  <a href="/">02</a>
                </li>
                <li>
                  <a href="/">03</a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-right" /> Next
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/*Projects section end*/}
    </div>

    {/*====================  End of project page content  ====================*/}

    {/* Brand logo */}
    <BrandLogoSlider background="grey-bg" />

    {/* Footer */}
    <Footer />

    {/* Mobile Menu */}
    <MobileMenu />
  </div>
);
  }
}
